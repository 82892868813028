.career {
    padding: 80px 0;
    background-color: #f4f4f4;
    color: #333;
    text-align: center;
}

.container {
    width: 90%;
    margin: 0 auto;
    max-width: 1200px;
}

.career h2 {
    font-size: 36px;
    color: #2c3e50;
    margin-bottom: 20px;
    font-weight: 700;
}

.career p {
    font-size: 18px;
    color: #7f8c8d;
    line-height: 1.6;
    margin-bottom: 40px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.career-opportunities {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.career-item {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 300px;
    transition: transform 0.3s, box-shadow 0.3s;
}

.career-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.career-item h3 {
    font-size: 24px;
    color: #2c3e50;
    margin-bottom: 15px;
    font-weight: 700;
}

.career-item p {
    font-size: 16px;
    color: #7f8c8d;
    margin-bottom: 20px;
}

.apply-link {
    display: inline-block;
    padding: 12px 24px;
    background-color: #3498db;
    color: #fff;
    border-radius: 4px;
    text-decoration: none;
    font-size: 16px;
    transition: background-color 0.3s;
}

.apply-link:hover {
    background-color: #2980b9;
}

@media (max-width: 768px) {
    .career-opportunities {
        flex-direction: column;
        align-items: center;
    }

    .career-item {
        width: 100%;
        max-width: 500px;
        margin-bottom: 20px;
    }
}
