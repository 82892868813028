.portfolio {
    padding: 80px 0;
    background: linear-gradient(135deg, #f4f4f4 50%, #e0e0e0 100%);
    text-align: center;
    
}

.container {
    width: 90%;
    margin: 0 auto;
    max-width: 1200px;
}

.portfolio h2 {
    font-size: 32px;
    margin-bottom: 40px;
    color: #333;
    font-weight: 700;
}

.portfolio-items {
    display: flex;
    justify-content: space-between;
}

.portfolio-item {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 15px;
    flex: 1 1 calc(50% - 30px); /* Two items per row with spacing */
    max-width: calc(50% - 30px); /* Ensures the item fits within the flex container */
    transition: transform 0.3s, box-shadow 0.3s;
}

.portfolio-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.portfolio-item h3 {
    font-size: 24px;
    margin-bottom: 15px;
    color: #333;
    font-weight: 700;
}

.portfolio-item p {
    font-size: 18px;
    color: #666;
    line-height: 1.6;
    margin-bottom: 20px;
}

.portfolio-link {
    display: inline-block;
    padding: 12px 24px;
    background-color: #007bff;
    color: #fff;
    border-radius: 4px;
    text-decoration: none;
    font-size: 18px;
    transition: background-color 0.3s, color 0.3s;
}

.portfolio-link:hover {
    background-color: #0056b3;
}
