.home {
    padding: 80px 0;
    background-color: #f4f4f4;
    text-align: center;
}

.container {
    width: 80%;
    margin: 0 auto;
}

.intro {
    margin-bottom: 40px;
}

.intro h1 {
    font-size: 36px;
    margin-bottom: 20px;
    color: #333;
}

.intro p {
    font-size: 18px;
    color: #555;
    margin: 20px 0;
}

.mission {
    background-color: #fff;
    padding: 40px 20px;
    border-radius: 8px;
    margin-bottom: 40px;
}

.mission h2 {
    font-size: 28px;
    margin-bottom: 20px;
    color: #333;
}

.mission p {
    font-size: 18px;
    color: #555;
}

.features {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 40px;
}

.feature-item {
    flex: 1;
    min-width: 250px;
    padding: 20px;
    margin: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.features h2 {
    font-size: 28px;
    margin-bottom: 20px;
    color: #333;
}

.feature-item h3 {
    font-size: 22px;
    margin-bottom: 10px;
}

.feature-item p {
    font-size: 18px;
    color: #666;
}

.testimonials {
    background-color: #fff;
    padding: 40px 20px;
    border-radius: 8px;
    margin-bottom: 40px;
}

.testimonials h2 {
    font-size: 28px;
    margin-bottom: 20px;
    color: #333;
}

.testimonial-item {
    margin-bottom: 20px;
    font-size: 18px;
    color: #555;
}

.testimonial-item cite {
    display: block;
    margin-top: 10px;
    font-style: italic;
    color: #777;
}

.cta {
    background-color: #007bff;
    color: #fff;
    padding: 40px 20px;
    border-radius: 8px;
}

.cta h2 {
    font-size: 28px;
    margin-bottom: 20px;
}

.cta p {
    font-size: 18px;
    margin-bottom: 20px;
}

.cta-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #fff;
    color: #007bff;
    border: 1px solid #007bff;
    border-radius: 4px;
    text-decoration: none;
    font-size: 18px;
}

.cta-button:hover {
    background-color: #e7e7e7;
}
