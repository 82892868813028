.services1 {
    padding: 50px 20px;
    background-color: #fff;
    text-align: center;
}

.services h2 {
    font-size: 28px;
    margin-bottom: 20px;
}

.service-list {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.service-item {
    background-color: #f8f8f8;
    padding: 20px;
    margin: 10px;
    border-radius: 8px;
    width: 30%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.service-item:hover {
    transform: translateY(-10px);
}
