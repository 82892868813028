.about {
    padding: 80px 0;
    background: linear-gradient(135deg, #f4f4f4 50%, #e0e0e0 100%);
    text-align: center;
}

.container {
    width: 90%;
    margin: 0 auto;
    max-width: 1200px;
}

.company-overview {
    margin-bottom: 60px;
}

.company-overview h1 {
    font-size: 40px;
    margin-bottom: 20px;
    color: #333;
    font-weight: 700;
}

.company-overview p {
    font-size: 20px;
    color: #555;
    margin: 0 auto;
    max-width: 800px;
    line-height: 1.6;
}

.services {
    margin-bottom: 60px;
    display: flex;
}

.services h2 {
    font-size: 32px;
    margin-bottom: 30px;
    color: #333;
    font-weight: 700;
}

.services-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.service-item {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 15px;
    flex: 1 1 calc(33.333% - 30px); /* Three items per row with spacing */
    transition: transform 0.3s, box-shadow 0.3s;
    max-width: calc(33.333% - 30px); /* Ensures the item fits within the flex container */
}

.service-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.service-item h3 {
    font-size: 24px;
    margin-bottom: 15px;
    color: #333;
    font-weight: 700;
}

.service-item p {
    font-size: 18px;
    color: #666;
    line-height: 1.6;
}

.our-team {
    margin-bottom: 60px;
}

.our-team h2 {
    font-size: 32px;
    margin-bottom: 30px;
    color: #333;
    font-weight: 700;
}

.team-member {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;
}

.team-member img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 15px;
    border: 3px solid #007bff;
    transition: border-color 0.3s;
}

.team-member img:hover {
    border-color: #0056b3;
}

.team-member h3 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
    font-weight: 700;
}

.team-member p {
    font-size: 18px;
    color: #666;
    text-align: center;
    line-height: 1.6;
}

.values {
    margin-bottom: 60px;
}

.values h2 {
    font-size: 32px;
    margin-bottom: 30px;
    color: #333;
    font-weight: 700;
}

.value-item {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    margin: 15px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.value-item h3 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
    font-weight: 700;
}

.value-item p {
    font-size: 18px;
    color: #666;
    line-height: 1.6;
}

.cta {
    background-color: #007bff;
    color: #fff;
    padding: 40px 20px;
    border-radius: 8px;
}

.cta h2 {
    font-size: 32px;
    margin-bottom: 20px;
    font-weight: 700;
}

.cta p {
    font-size: 20px;
    margin-bottom: 20px;
    line-height: 1.6;
}

.cta-button {
    display: inline-block;
    padding: 12px 24px;
    background-color: #fff;
    color: #007bff;
    border: 2px solid #007bff;
    border-radius: 4px;
    text-decoration: none;
    font-size: 18px;
    transition: background-color 0.3s, color 0.3s;
}

.cta-button:hover {
    background-color: #e7e7e7;
    color: #0056b3;
}
