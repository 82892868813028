
.header {
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 0;
    background: #333;
    color: #fff;
    padding: 20px 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* Ensures the header stays on top of other content */
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    max-width: 1200px;
}

.logo img {
    max-width: 100px; /* Limit logo size */
    height: auto;
    transition: transform 0.3s ease; /* Smooth transition for hover effect */
}

.logo img:hover {
    transform: scale(1.1); /* Slight zoom effect on hover */
}

.nav-links {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 20px;
}

.nav-links li {
    position: relative;
}

.nav-links a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    padding: 5px 0; /* Added padding for better click area */
}

.nav-links a:hover {
    color: #f0f0f0;
    border-bottom: 2px solid #fff; /* Bottom border on hover */
}

.nav-links a::before {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 0;
    height: 2px;
    background: #fff;
    transition: width 0.3s ease;
}

.nav-links a:hover::before {
    width: 100%; /* Expands underline on hover */
}

@media (max-width: 768px) {
    .navbar {
        flex-direction: column;
        align-items: flex-start;
    }

    .nav-links {
        flex-direction: column;
        gap: 10px;
        width: 100%;
    }

    .nav-links a {
        font-size: 16px;
    }
}


