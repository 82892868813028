.contact {
    padding: 80px 0;
    background-color: #f4f4f4;
    color: #333;
    text-align: center;
}

.container {
    width: 90%;
    margin: 0 auto;
    max-width: 1200px;
}

.contact h2 {
    font-size: 36px;
    color: #2c3e50;
    margin-bottom: 20px;
    font-weight: 700;
}

.contact p {
    font-size: 18px;
    color: #7f8c8d;
    line-height: 1.6;
    margin-bottom: 40px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.contact-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    margin-bottom: 40px;
}

.contact-item {
    /* background-color: #fff; */
    border-radius: 8px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    padding: 20px;
    width: 300px;
    text-align: left;
}

.contact-item h3 {
    font-size: 24px;
    color: #2c3e50;
    margin-bottom: 15px;
    font-weight: 700;
}

.contact-item p {
    font-size: 16px;
    color: #7f8c8d;
    margin-bottom: 20px;
}

.contact-form {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    text-align: left;
}

.contact-form h3 {
    font-size: 24px;
    color: #2c3e50;
    margin-bottom: 20px;
    font-weight: 700;
}

.form-group {
    margin-bottom: 15px;

}

.form-group label {
    display: block;
    font-size: 16px;
    color: #333;
    margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
    width: 99%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.form-group textarea {
    resize: vertical;
}

.submit-button {
    display: inline-block;
    padding: 12px 24px;
    background-color: #3498db;
    color: #fff;
    border-radius: 4px;
    text-decoration: none;
    font-size: 16px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submit-button:hover {
    background-color: #2980b9;
}
